import { strapiPublicRequest } from './apiHelper';

export async function login(username, password) {
  try {
    const res = await strapiPublicRequest('auth/local', 'post', { identifier: username, password });
    if (res.jwt) {
      localStorage.setItem('syncaida-token', res.jwt);
      return 'success';
    } else {
      throw res.error;
    }
  } catch (err) {
    return err;
  }
}
