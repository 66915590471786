/* eslint-disable no-unused-vars */
import React, { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { fetchCharacters, fetchClasses, fetchSectionIds, getImageForPlatform } from './api';
import { PiUserCirclePlus } from 'react-icons/pi';
import AddEditCharacterModal from './AddEditCharacterModal';

export default function PSOCharacterListView() {
  const [characters, setCharacters] = useState();
  const [sectionIds, setSectionIds] = useState();
  const [classes, setClasses] = useState();
  const [loading, setLoading] = useState(true);
  const [isCreateModalOpen, setIsCreateModalOpen] = useState(false);
  const navigate = useNavigate();

  useEffect(() => {
    if (!localStorage.getItem('syncaida-token')) {
      navigate('/login');
      return;
    }
    async function fetchData() {
      try {
        const [charactersRes, sectionIdsRes, classesRes] = await Promise.all([
          fetchCharacters(),
          fetchSectionIds(),
          fetchClasses(),
        ]);
        setCharacters(charactersRes);
        setSectionIds(sectionIdsRes);
        setClasses(classesRes);
        setLoading(false);
      } catch (err) {
        console.error(err);
      }
    }
    fetchData();
  }, []);

  async function refetchCharacters() {
    try {
      const newCharacters = await fetchCharacters();
      setCharacters(newCharacters);
    } catch (err) {
      console.error(err);
    }
  }

  function selectCharacter(id) {
    navigate(`/pso-character-tracker/${id}`);
  }

  // add a header component that has a button to add a new character and return to list
  // convert selected character to number, and use that to get the character from the array
  // then the header can have a next and back button to go to the next or previous character
  return (
    <>
      <div className='pso-character-tracker-wrapper'>
        <h1>PSO Character Tracker</h1>
        {loading ? (
          <div>Loading...</div>
        ) : (
          <>
            {classes && sectionIds && (
              <AddEditCharacterModal
                visible={isCreateModalOpen}
                sectionIds={sectionIds}
                classes={classes}
                setIsOpen={setIsCreateModalOpen}
                refetchCharacters={refetchCharacters}
              />
            )}
            <div
              style={{
                display: 'flex',
                flexDirection: 'column',
                alignItems: 'center',
                height: '100%',
                width: '100%',
                padding: '0 16px 16px 16px',
              }}
            >
              <div className='pso-character-list-wrapper'>
                {characters &&
                  characters.length > 0 &&
                  characters.map((character, i) => (
                    <button
                      onClick={() => selectCharacter(character.id)}
                      key={`character-${character.id}`}
                      className='pso-character-card'
                      style={{ maxWidth: '500px' }}
                    >
                      <div
                        style={{
                          height: '100%',
                          gridColumn: '3/4',
                          gridRow: '1/6',
                          justifySelf: 'center',
                          display: 'flex',
                          justifyContent: 'space-evenly',
                          alignItems: 'center',
                          flexDirection: 'column',
                          gap: '8px',
                        }}
                      >
                        <img
                          src={character.pso_section_id.image.url}
                          alt={character.pso_section_id.name}
                          style={{ filter: 'drop-shadow(0 0 2px black)', maxHeight: '48px', maxWidth: '48px' }}
                        />
                        {getImageForPlatform(character.platform) && (
                          <img
                            src={getImageForPlatform(character.platform)}
                            alt={character.platform}
                            style={{ filter: 'drop-shadow(0 0 2px black)', maxHeight: '30px' }}
                          />
                        )}
                      </div>
                      <span style={{ justifySelf: 'flex-end' }}>Name</span>
                      <span>:{character.name}</span>
                      <span style={{ justifySelf: 'flex-end' }}>Class</span>
                      <span>:{character.pso_class.name}</span>
                      <span style={{ justifySelf: 'flex-end' }}>Level</span>
                      <span>:{character.level}</span>
                    </button>
                  ))}
                <button
                  onClick={() => setIsCreateModalOpen(true)}
                  className='pso-character-card'
                  style={{ maxWidth: '500px' }}
                >
                  <PiUserCirclePlus
                    size='50px'
                    color='white'
                    style={{ filter: 'drop-shadow(0 0 2px black)', gridColumn: '1/-1', justifySelf: 'center' }}
                  />
                  <span style={{ gridColumn: '1/-1', justifySelf: 'center' }}>Create Character</span>
                </button>
              </div>
              <div style={{ flexGrow: 1 }} />
            </div>
          </>
        )}
      </div>
    </>
  );
}
