import React, { useEffect, useState } from 'react';
// import { fakeExpenses, fakeAccounts, fakeTypes } from './tempData';
import { strapiPublicRequest } from '../../utils/apiHelper';

const useBudgeter = () => {
  const [expenses, setExpenses] = useState(null);
  const [accounts, setAccounts] = useState(null);
  const [remaining, setRemaining] = useState(null);
  const [types, setTypes] = useState(null);
  const [expenseModalVisible, setExpenseModalVisible] = useState(false);
  const [accountModalVisible, setAccountModalVisible] = useState(false);

  const [accountOptions, setAccountOptions] = useState([]);
  const [typeOptions, setTypeOptions] = useState([]);

  const [accountId, setAccountId] = useState(null);
  const [accountName, setAccountName] = useState('');
  const [accountValue, setAccountValue] = useState(0.01);

  const [expenseId, setExpenseId] = useState(null);
  const [expenseName, setExpenseName] = useState('');
  const [expenseDate, setExpenseDate] = useState('');
  const [expenseTypeId, setExpenseTypeId] = useState(0);
  const [expensePaid, setExpensePaid] = useState(false);
  const [expenseAccountId, setExpenseAccountId] = useState(0);
  const [expenseValue, setExpenseValue] = useState(0);

  useEffect(() => {
    const getData = async () => {
      try {
        //TODO: Convert to strapi request
        const res = await strapiPublicRequest('p/budgeter/self/all');
        if (res) {
          setExpenses(res.expenses);
          setAccounts(res.accounts);
          setTypes(res.types);
        }
      } catch (err) {
        console.error(err);
      }
    };
    getData();
  }, []);

  useEffect(() => {
    const tempRemaining = {};
    Object.keys(accounts).map(id => {
      tempRemaining[id] = { ...accounts[id] };
    });
    for (const expense of expenses) {
      if (!expense.paid) {
        if (tempRemaining[expense.accountId]) {
          tempRemaining[expense.accountId].value += expense.value;
        }
      }
    }
    generateAccountOptions();
    setRemaining(tempRemaining);
  }, [accounts]);

  useEffect(() => {
    generateTypeOptions();
  }, [types]);

  const generateAccountOptions = () => {
    const output = [];
    for (const id of Object.keys(accounts)) {
      const account = accounts[id];
      output.push(
        <option key={id + account.name} value={id}>
          {account.name}
        </option>
      );
    }
    setAccountOptions(output);
  };

  const generateTypeOptions = () => {
    const output = [];
    for (const id of Object.keys(types)) {
      const type = types[id];
      output.push(
        <option key={id + type.name} value={id}>
          {type.name}
        </option>
      );
    }
    setTypeOptions(output);
  };

  const openExpensesModal = (id = null) => {
    if (id) {
      let thisExpense = expenses.filter(expense => expense.id === id);
      if (thisExpense.length > 0) {
        thisExpense = thisExpense[0];
        setExpenseDate(thisExpense.date);
        setExpenseName(thisExpense.name);
        setExpenseValue(thisExpense.value);
        setExpensePaid(thisExpense.paid);
        setExpenseTypeId(thisExpense.typeId);
        setExpenseAccountId(thisExpense.accountId);
      }
    } else {
      setAccountName('');
      setAccountValue(0.01);
    }
    setExpenseId(id);
    setExpenseModalVisible(true);
  };

  const openAccountModal = (id = null) => {
    if (id) {
      const thisAccount = accounts[id];
      if (thisAccount.length > 0) {
        setAccountName(thisAccount.name);
        setAccountValue(thisAccount.value);
      }
    } else {
      setAccountName('');
      setAccountValue(0.01);
    }
    setAccountId(id);
    setAccountModalVisible(true);
  };

  return {
    expenses,
    accounts,
    remaining,
    types,
    accountModalVisible,
    expenseModalVisible,
    accountOptions,
    typeOptions,
    accountId,
    accountName,
    accountValue,
    expenseId,
    expenseName,
    expenseDate,
    expenseTypeId,
    expensePaid,
    expenseAccountId,
    expenseValue,
    setExpenses,
    setAccounts,
    setRemaining,
    setTypes,
    setExpenseModalVisible,
    setAccountModalVisible,
    setAccountOptions,
    setTypeOptions,
    setAccountId,
    setAccountName,
    setAccountValue,
    setExpenseId,
    setExpenseName,
    setExpenseDate,
    setExpenseTypeId,
    setExpensePaid,
    setExpenseAccountId,
    setExpenseValue,
    openExpensesModal,
    openAccountModal,
  };
};

export default useBudgeter;
