import React, { useState } from 'react';
import { LwsCard, LwsCardBody, LwsCardFooter } from '../components/ContainerComponents';
import { LwsButton, LwsLabeledInput } from '../components/BasicComponents';
import { strapiPublicRequest } from '../utils/apiHelper';
import { Link } from 'react-router-dom';

function ForgotPassword() {
  const [email, setEmail] = useState('');
  const [error, setError] = useState('');
  const [success, setSuccess] = useState('');

  const attemptLogin = async () => {
    try {
      setSuccess('');
      const res = await strapiPublicRequest('auth/forgot-password', 'post', { email });
      if (res?.error) {
        throw new Error(res.error.message);
      }
      setError('');
      setSuccess('Password reset link sent. Check your email for further instructions.');
    } catch (err) {
      console.error(err);
      setError(err.message);
      setSuccess('');
    }
  };

  return (
    <div>
      <h1>{`${localStorage.getItem('syncaida-token') ? 'Change' : 'Forgot'} Password`}</h1>
      <div className='center-flex'>
        <LwsCard className='full-width width-sm-max'>
          <LwsCardBody>
            {error !== '' ? <p className='card__error'>{error}</p> : null}
            {success !== '' ? <p className='card__success'>{success}</p> : null}
            <LwsLabeledInput
              label='Email'
              name='email'
              type='text'
              value={email}
              onChange={e => setEmail(e.target.value)}
            />
          </LwsCardBody>
          <LwsCardFooter>
            <LwsButton onClick={attemptLogin}>Send Reset Link</LwsButton>
            <Link to='/login'>Cancel</Link>
          </LwsCardFooter>
        </LwsCard>
      </div>
    </div>
  );
}

export default ForgotPassword;
