/* eslint-disable no-unused-vars */
import React, { useEffect, useState } from 'react';
import { BiMinus, BiPencil, BiPlus, BiSave, BiTrash } from 'react-icons/bi';
import { LwsInput, LwsTextArea } from '../../components/BasicComponents';

export default function MagTracker({ mag, saveMag, index, deleteMag }) {
  const [tempDefCurrent, setTempDefCurrent] = useState(mag.def_current ?? 0);
  const [tempPowCurrent, setTempPowCurrent] = useState(mag.pow_current ?? 0);
  const [tempDexCurrent, setTempDexCurrent] = useState(mag.dex_current ?? 0);
  const [tempMindCurrent, setTempMindCurrent] = useState(mag.mind_current ?? 0);
  const [tempDefPlanned, setTempDefPlanned] = useState(mag.def_planned ?? 0);
  const [tempPowPlanned, setTempPowPlanned] = useState(mag.pow_planned ?? 0);
  const [tempDexPlanned, setTempDexPlanned] = useState(mag.dex_planned ?? 0);
  const [tempMindPlanned, setTempMindPlanned] = useState(mag.mind_planned ?? 0);
  const [tempNotes, setTempNotes] = useState(mag.notes ?? '');
  const [tempMagName, setTempMagName] = useState(mag.mag_name ?? '');
  const [isEditing, setIsEditing] = useState(false);
  const [isSaving, setIsSaving] = useState(false);
  const [needsSaving, setNeedsSaving] = useState(false);

  useEffect(() => {
    const interval = setTimeout(() => {
      if (needsSaving) {
        saveChanges();
      }
    }, 1000);
    return () => clearTimeout(interval);
  }, [needsSaving, tempDefCurrent, tempPowCurrent, tempDexCurrent, tempMindCurrent]);

  async function saveChanges() {
    setIsSaving(true);
    const tempMag = getTempMag();
    await saveMag(tempMag, index);
    setNeedsSaving(false);
    setIsEditing(false);
    setIsSaving(false);
  }

  function getTempMag() {
    return {
      ...mag,
      def_current: tempDefCurrent,
      pow_current: tempPowCurrent,
      dex_current: tempDexCurrent,
      mind_current: tempMindCurrent,
      notes: tempNotes,
      mag_name: tempMagName,
    };
  }

  function changeDefCurrent(amount) {
    setNeedsSaving(true);
    setTempDefCurrent(tempDefCurrent + amount);
  }

  function changePowCurrent(amount) {
    setNeedsSaving(true);
    setTempPowCurrent(tempPowCurrent + amount);
  }

  function changeDexCurrent(amount) {
    setNeedsSaving(true);
    setTempDexCurrent(tempDexCurrent + amount);
  }

  function changeMindCurrent(amount) {
    setNeedsSaving(true);
    setTempMindCurrent(tempMindCurrent + amount);
  }

  function totalCurrent() {
    return tempDefCurrent + tempPowCurrent + tempDexCurrent + tempMindCurrent;
  }

  function totalPlanned() {
    return tempDefPlanned + tempPowPlanned + tempDexPlanned + tempMindPlanned;
  }

  return (
    <div
      className='pso-character-card'
      style={{
        alignItems: 'center',
        maxWidth: '600px',
        display: 'grid',
        gridTemplateColumns: '1fr auto',
        justifyItems: 'center',
        columnGap: '32px',
        rowGap: '8px',
      }}
    >
      {needsSaving && (
        <span
          style={{
            color: 'MediumSeaGreen',
            position: 'absolute',
            top: '4px',
            left: '50%',
            fontSize: '18px',
            transform: 'translateX(-50%)',
          }}
        >
          Saving...
        </span>
      )}
      <div
        style={{
          width: '100%',
          gridColumn: '1/-1',
          display: 'grid',
          gridTemplateColumns: 'auto 1fr auto',
          justifyItems: 'center',
          columnGap: '32px',
          rowGap: '8px',
        }}
      >
        <button style={{ justifySelf: 'flex-end' }} className='pso-button' onClick={() => deleteMag(index)}>
          <BiTrash size='24px' color='white' />
        </button>
        {isEditing ? (
          <LwsInput
            value={tempMagName}
            onChange={e => setTempMagName(e.target.value)}
            placeholder='Mag Name'
            style={{ margin: '8px 0', textAlign: 'center', color: '#d8d8d8' }}
          />
        ) : (
          <h3 style={{ margin: '8px 0' }}>{tempMagName ?? 'Mag'}</h3>
        )}
        {isEditing ? (
          <button
            style={{ justifySelf: 'flex-end' }}
            className='pso-button'
            disabled={isSaving}
            onClick={() => saveChanges()}
          >
            <BiSave size='24px' color='white' />
          </button>
        ) : (
          <button style={{ justifySelf: 'flex-end' }} className='pso-button' onClick={() => setIsEditing(true)}>
            <BiPencil size='24px' color='white' />
          </button>
        )}
      </div>

      <span style={{ justifySelf: 'start' }}>Lv.</span>
      <span
        style={{
          color:
            totalCurrent() > 200 || totalPlanned() > 200 || totalCurrent() < 5 || totalPlanned() < 5
              ? '#ff3939'
              : totalCurrent() === totalPlanned()
              ? 'MediumSeaGreen'
              : '',
        }}
      >
        {totalCurrent()} / {totalPlanned()}
      </span>

      <span style={{ justifySelf: 'start' }}>DEF</span>
      {isEditing ? (
        <div style={{ display: 'flex', gap: '16px' }}>
          <LwsInput
            value={tempDefCurrent}
            onChange={e => setTempDefCurrent(parseInt(e.target.value))}
            type='number'
            style={{ width: '90px' }}
            min={5}
            max={200}
          />
          <span> / </span>
          <LwsInput
            value={tempDefPlanned}
            onChange={e => setTempDefPlanned(parseInt(e.target.value))}
            type='number'
            style={{ width: '90px' }}
            min={5}
            max={200}
          />
        </div>
      ) : (
        <div className='pso-mats-controls'>
          <button className='pso-button' disabled={totalCurrent() <= 5} onClick={() => changeDefCurrent(-1)}>
            <BiMinus size='24px' />
          </button>
          <span
            style={{
              color:
                tempDefCurrent > 200 || tempDefPlanned > 200 || tempDefCurrent < 5 || tempDefPlanned < 5
                  ? '#ff3939'
                  : tempDefCurrent === tempDefPlanned
                  ? 'MediumSeaGreen'
                  : '',
            }}
          >
            {tempDefCurrent ?? 5} / {tempDefPlanned ?? 0}
          </span>
          <button className='pso-button' onClick={() => changeDefCurrent(1)} disabled={totalCurrent() >= 200}>
            <BiPlus size='24px' />
          </button>
        </div>
      )}

      <span style={{ justifySelf: 'start' }}>POW</span>
      {isEditing ? (
        <div style={{ display: 'flex', gap: '16px' }}>
          <LwsInput
            value={tempPowCurrent}
            onChange={e => setTempPowCurrent(parseInt(e.target.value))}
            type='number'
            style={{ width: '90px' }}
            min={0}
            max={195}
          />
          <span> / </span>
          <LwsInput
            value={tempPowPlanned}
            onChange={e => setTempPowPlanned(parseInt(e.target.value))}
            type='number'
            style={{ width: '90px' }}
            min={0}
            max={195}
          />
        </div>
      ) : (
        <div className='pso-mats-controls'>
          <button className='pso-button' disabled={totalCurrent() <= 5} onClick={() => changePowCurrent(-1)}>
            <BiMinus size='24px' />
          </button>
          <span
            style={{
              color:
                tempPowCurrent > 195 || tempPowPlanned > 195 || tempPowCurrent < 0 || tempPowPlanned < 0
                  ? '#ff3939'
                  : tempPowCurrent === tempPowPlanned
                  ? 'MediumSeaGreen'
                  : '',
            }}
          >
            {tempPowCurrent ?? 0} / {tempPowPlanned ?? 0}
          </span>
          <button className='pso-button' onClick={() => changePowCurrent(1)} disabled={totalCurrent() >= 200}>
            <BiPlus size='24px' />
          </button>
        </div>
      )}

      <span style={{ justifySelf: 'start' }}>DEX</span>
      {isEditing ? (
        <div style={{ display: 'flex', gap: '16px' }}>
          <LwsInput
            value={tempDexCurrent}
            onChange={e => setTempDexCurrent(parseInt(e.target.value))}
            type='number'
            style={{ width: '90px' }}
            min={0}
            max={195}
          />
          <span> / </span>
          <LwsInput
            value={tempDexPlanned}
            onChange={e => setTempDexPlanned(parseInt(e.target.value))}
            type='number'
            style={{ width: '90px' }}
            min={0}
            max={195}
          />
        </div>
      ) : (
        <div className='pso-mats-controls'>
          <button className='pso-button' disabled={totalCurrent() <= 5} onClick={() => changeDexCurrent(-1)}>
            <BiMinus size='24px' />
          </button>
          <span
            style={{
              color:
                tempDexCurrent > 195 || tempDexPlanned > 195 || tempDexCurrent < 0 || tempDexPlanned < 0
                  ? '#ff3939'
                  : tempDexCurrent === tempDexPlanned
                  ? 'MediumSeaGreen'
                  : '',
            }}
          >
            {tempDexCurrent ?? 0} / {tempDexPlanned ?? 0}
          </span>
          <button className='pso-button' onClick={() => changeDexCurrent(1)} disabled={totalCurrent() >= 200}>
            <BiPlus size='24px' />
          </button>
        </div>
      )}

      <span style={{ justifySelf: 'start' }}>MIND</span>
      {isEditing ? (
        <div style={{ display: 'flex', gap: '16px' }}>
          <LwsInput
            value={tempMindCurrent}
            onChange={e => setTempMindCurrent(parseInt(e.target.value))}
            type='number'
            style={{ width: '90px' }}
            min={0}
            max={195}
          />
          <span> / </span>
          <LwsInput
            value={tempMindPlanned}
            onChange={e => setTempMindPlanned(parseInt(e.target.value))}
            type='number'
            style={{ width: '90px' }}
            min={0}
            max={195}
          />
        </div>
      ) : (
        <div className='pso-mats-controls'>
          <button className='pso-button' disabled={totalCurrent() <= 5} onClick={() => changeMindCurrent(-1)}>
            <BiMinus size='24px' />
          </button>
          <span
            style={{
              color:
                tempMindCurrent > 195 || tempMindPlanned > 195 || tempMindCurrent < 0 || tempMindPlanned < 0
                  ? '#ff3939'
                  : tempMindCurrent === tempMindPlanned
                  ? 'MediumSeaGreen'
                  : '',
            }}
          >
            {tempMindCurrent ?? 0} / {tempMindPlanned ?? 0}
          </span>
          <button className='pso-button' onClick={() => changeMindCurrent(1)} disabled={totalCurrent() >= 200}>
            <BiPlus size='24px' />
          </button>
        </div>
      )}
      {isEditing ? (
        <LwsTextArea
          style={{ gridColumn: '1/-1', margin: '8px 0', width: '100%', whiteSpace: 'pre-wrap' }}
          value={tempNotes}
          rows={8}
          onChange={e => setTempNotes(e.target.value)}
          placeholder='Notes'
        />
      ) : (
        <p style={{ gridColumn: '1/-1', margin: '8px 0', whiteSpace: 'pre-wrap' }}>{tempNotes}</p>
      )}
    </div>
  );
}
