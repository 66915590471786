/* eslint-disable quotes */
import React from 'react';

function NotFound() {
  const messages = [
    "Looks like you're lost, friendo.",
    "You're in the wrong part of town.",
    'You lost, buddy?',
    "You're not in Kansas anymore.",
    "The page you're looking for doesn't exist.",
    "You've wandered too far off the path.",
    "You've located planet PNF-404, but your Pikmin are in another castle.",
  ];
  const message = messages[Math.floor(Math.random() * messages.length)];
  return (
    <>
      <h1 style={{ width: '100%', textAlign: 'center' }}>{message}</h1>
    </>
  );
}

export default NotFound;
