import React, { useState } from 'react';
import { LwsCard, LwsCardBody, LwsCardFooter } from '../components/ContainerComponents';
import { LwsButton, LwsLabeledInput } from '../components/BasicComponents';
import { Link } from 'react-router-dom';
import { login } from '../utils/authApiHelper';

function Login() {
  const [username, setUsername] = useState('');
  const [password, setPassword] = useState('');
  const [error, setError] = useState('');

  const attemptLogin = async () => {
    try {
      const res = await login(username, password);
      if (res === 'success') {
        document.location.href = '/';
      } else {
        throw res;
      }
    } catch (err) {
      console.error(err);
      setError(err.message);
    }
  };

  return (
    <div>
      <h1>Login</h1>
      <div className='center-flex'>
        <LwsCard className='full-width width-sm-max'>
          <LwsCardBody>
            {error !== '' ? <p className='card__error'>{error}</p> : null}
            <LwsLabeledInput
              label='Username'
              name='username'
              type='text'
              value={username}
              onChange={e => setUsername(e.target.value)}
            />
            <LwsLabeledInput
              label='Password'
              name='password'
              type='password'
              value={password}
              onChange={e => setPassword(e.target.value)}
            />
          </LwsCardBody>
          <LwsCardFooter>
            <LwsButton onClick={attemptLogin}>Login</LwsButton>
            <Link to='/forgot-password'>Forgot Password</Link>
          </LwsCardFooter>
        </LwsCard>
      </div>
    </div>
  );
}

export default Login;
