/* eslint-disable no-unused-vars */
/* eslint-disable react/no-unknown-property */
import React, { useEffect, useRef } from 'react';
import { Vector3 } from 'three';
import { usePlane, useBox } from '@react-three/cannon';

export function getRandomColor() {
  const color = '#' + ((Math.random() * 0xffffff) << 0).toString(16).padStart(6, '0');
  return color;
}

export function Plane({ args, color, opacity, ...props }) {
  const [ref] = usePlane(() => ({ mass: 0, ...props }), useRef());
  return (
    <mesh ref={ref} receiveShadow>
      <planeGeometry args={args ?? [25, 25]} />
      <meshPhongMaterial color={color ?? '#ffffff'} opacity={opacity ?? 0.0} transparent />
    </mesh>
  );
}

export function Cube({ args = [1, 1, 1], color, opacity, spread = 10, ...props }) {
  const [ref, api] = useBox(
    () => ({
      ...props,
      args: args,
      mass: 1,
      rotation: [Math.random() * 90, Math.random() * 90, Math.random() * 90],
    }),
    useRef()
  );

  useEffect(() => {
    const pos = new Vector3(props.position[0], props.position[1], props.position[2]);
    const target = new Vector3(Math.random() * spread - spread / 2, 4, Math.random() * spread - spread / 2);
    const direction = target.sub(pos);
    const distance = direction.length() / 2;
    const normal = direction.normalize();
    api.applyImpulse([normal.x * distance, normal.y * distance, normal.z * distance], [0, 0, 0]);
  }, []);

  return (
    //onPointerDown={() => api.velocity.set(0, 5, 0)}
    <mesh ref={ref} castShadow>
      <boxGeometry args={args ?? [1, 1, 1]} />
      <meshPhongMaterial color={color ?? getRandomColor()} opacity={opacity ?? 1} transparent />
    </mesh>
  );
}
