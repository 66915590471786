import React from 'react';
import { LwsButton } from './BasicComponents';

export const LwsCard = props => {
  const { className, children, style } = props;
  return (
    <div className={'lws-card ' + (className ?? '')} style={style}>
      {children}
    </div>
  );
};

export const LwsCardHeader = props => {
  const { className, children } = props;
  return <div className={'lws-card__header ' + (className ?? '')}>{children}</div>;
};

export const LwsCardBody = props => {
  const { className, children } = props;
  return <div className={'lws-card__body ' + (className ?? '')}>{children}</div>;
};

export const LwsCardFooter = props => {
  const { className, children } = props;
  return <div className={'lws-card__footer ' + (className ?? '')}>{children}</div>;
};

export const LwsModal = props => {
  const {
    children,
    title,
    onClose,
    className,
    visible,
    footerConfirmLabel,
    footerConfirmAction,
    footerDangerLabel,
    footerDangerAction,
    disabled = false,
  } = props;

  const hasSuccess = footerConfirmLabel && footerConfirmAction;
  const hasDanger = footerDangerLabel && footerDangerAction;
  return (
    <div className={`lws-modal ${visible ? '' : '--hidden'}`}>
      <button className='modal__veil' onClick={onClose}></button>
      <LwsCard className={`modal__card ${className ?? ''}`}>
        <LwsCardHeader>
          <h2>{title}</h2>
          <LwsButton onClick={onClose}>
            <i className='fa-solid fa-xmark'></i>
          </LwsButton>
        </LwsCardHeader>
        <LwsCardBody>{children}</LwsCardBody>
        {(hasSuccess || hasDanger) && (
          <LwsCardFooter>
            {hasSuccess && (
              <LwsButton disabled={disabled} type='submit' onClick={e => footerConfirmAction(e)}>
                {footerConfirmLabel}
              </LwsButton>
            )}
            {hasDanger && (
              <LwsButton disabled={disabled} type='submit' className='--danger' onClick={e => footerDangerAction(e)}>
                {footerDangerLabel}
              </LwsButton>
            )}
          </LwsCardFooter>
        )}
      </LwsCard>
    </div>
  );
};
