import React from 'react';
import { LwsCard, LwsCardBody, LwsCardHeader, LwsModal } from '../../components/ContainerComponents';
import { LwsButton, LwsLabeledInput, LwsLabeledSelect } from '../../components/BasicComponents';
import useBudgeter from './useBudgeter';

//TODO: Rebuild to use strapi
// Componentize this much more, pull actions out into modals.
function Budgeter() {
  const {
    expenses,
    accounts,
    remaining,
    types,
    accountModalVisible,
    expenseModalVisible,
    accountOptions,
    typeOptions,
    accountId,
    accountName,
    accountValue,
    expenseId,
    expenseName,
    expenseDate,
    expenseTypeId,
    expensePaid,
    expenseAccountId,
    expenseValue,
    setExpenseModalVisible,
    setAccountModalVisible,
    setAccountName,
    setAccountValue,
    setExpenseName,
    setExpenseDate,
    setExpenseTypeId,
    setExpensePaid,
    setExpenseAccountId,
    setExpenseValue,
    openExpensesModal,
    openAccountModal,
  } = useBudgeter();

  const ExpensesModal = () => (
    <LwsModal
      visible={expenseModalVisible}
      className='fw width-sm-max'
      title={`${expenseId ? 'Edit' : 'Add'} Expense`}
      onClose={() => setExpenseModalVisible(false)}
    >
      <LwsLabeledInput
        label='Name'
        name='name'
        type='text'
        value={expenseName}
        onChange={e => setExpenseName(e.target.value)}
      />
      <LwsLabeledInput
        label='Date'
        name='date'
        type='text'
        value={expenseDate}
        onChange={e => setExpenseDate(e.target.value)}
      />
      <LwsLabeledSelect
        name='selectType'
        label='Type'
        value={expenseTypeId}
        onChange={e => setExpenseTypeId(e.target.value)}
      >
        {typeOptions}
      </LwsLabeledSelect>
      <LwsLabeledInput
        label='Value'
        name='value'
        type='number'
        value={expenseValue}
        step='0.01'
        onChange={e => setExpenseValue(e.target.value)}
      />
      <LwsLabeledSelect
        name='selectAccount'
        label='Account'
        value={expenseAccountId}
        onChange={e => setExpenseAccountId(e.target.value)}
      >
        {accountOptions}
      </LwsLabeledSelect>
      <input type='checkbox' checked={expensePaid} onChange={e => setExpensePaid(e.target.checked)} />
      <LwsButton onClick={() => setExpenseModalVisible(false)}>Save</LwsButton>
    </LwsModal>
  );

  const AccountsModal = () => (
    <LwsModal
      visible={accountModalVisible}
      className='fw width-sm-max'
      title={`${accountId ? 'Edit' : 'Add'} Account`}
      onClose={() => setAccountModalVisible(false)}
    >
      <LwsLabeledInput
        label='Name'
        name='name'
        type='text'
        value={accountName}
        onChange={e => setAccountName(e.target.value)}
      />
      <LwsLabeledInput
        label='Value'
        name='value'
        type='number'
        value={accountValue}
        step='0.01'
        onChange={e => setAccountValue(e.target.value)}
      />
      <LwsButton onClick={() => setAccountModalVisible(false)}>Save</LwsButton>
    </LwsModal>
  );

  return (
    accounts &&
    expenses &&
    remaining &&
    types && (
      <>
        <ExpensesModal />
        <AccountsModal />
        <h1>Budgeter (WIP)</h1>
        <div className='budget-container'>
          <LwsCard className='budget-expenses full-width'>
            <LwsCardHeader>
              <h2>Expenses</h2>
              <LwsButton onClick={() => setExpenseModalVisible(true)}>Add</LwsButton>
            </LwsCardHeader>
            <LwsCardBody className='expenses-body'>
              <b>Pay Date</b>
              <b>Type</b>
              <b>Value</b>
              <b style={{ justifySelf: 'start' }}>Name</b>
              <b>Paid</b>
              <b>Account</b>
              <hr />
              {expenses.length > 0
                ? expenses.map((expense, i) => (
                    <React.Fragment key={expense.id + 'expenseFragment'}>
                      <span>{expense.date}</span>
                      <span>{types[expense.typeId].name}</span>
                      <b className={expense.value > 0 ? 'text-green' : 'text-red'}>
                        ${Number.parseFloat(expense.value).toFixed(2)}
                      </b>
                      <span className='expense__name'>{expense.name}</span>
                      <input type='checkbox' readOnly checked={expense.paid} />
                      <span>{accounts[expense.accountId].name}</span>
                      <button onClick={() => openExpensesModal(expense.id)}>
                        <i className='fa-solid fa-pen-to-square'></i>
                      </button>
                      {i === expenses.length - 1 ? null : <hr />}
                    </React.Fragment>
                  ))
                : null}
            </LwsCardBody>
          </LwsCard>

          <LwsCard className='budget-accounts full-width'>
            <LwsCardHeader>
              <h2>Accounts</h2>
              <LwsButton onClick={() => openAccountModal()}>Add</LwsButton>
            </LwsCardHeader>
            <LwsCardBody className='accounts-body'>
              {Object.keys(accounts).length > 0
                ? Object.keys(accounts).map((id, i) => {
                    const account = accounts[id];
                    return (
                      <React.Fragment key={id + 'accountFragment'}>
                        <span>{account.name}</span>{' '}
                        <b className={account.value > 0 ? 'text-green' : 'text-red'}>
                          ${Number.parseFloat(account.value).toFixed(2)}
                        </b>
                        <button onClick={() => openAccountModal(account.id)}>
                          <i className='fa-solid fa-pen-to-square'></i>
                        </button>
                        {i === Object.keys(accounts).length - 1 ? null : <hr />}
                      </React.Fragment>
                    );
                  })
                : null}
            </LwsCardBody>
          </LwsCard>

          <LwsCard className='budget-remaining full-width'>
            <LwsCardHeader>
              <h2>Remaining</h2>
            </LwsCardHeader>
            <LwsCardBody className='remaining-body'>
              {Object.keys(remaining).length > 0
                ? Object.keys(remaining).map((id, i) => {
                    const account = remaining[id];
                    return (
                      <React.Fragment key={id + 'remainingFragment'}>
                        <span>{account.name}</span>{' '}
                        <b className={account.value > 0 ? 'text-green' : 'text-red'}>
                          ${Number.parseFloat(account.value).toFixed(2)}
                        </b>
                        {i === Object.keys(remaining).length - 1 ? null : <hr />}
                      </React.Fragment>
                    );
                  })
                : null}
            </LwsCardBody>
          </LwsCard>
        </div>
      </>
    )
  );
}

export default Budgeter;
