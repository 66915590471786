import React, { useEffect } from 'react';

function Logout() {
  useEffect(() => {
    localStorage.removeItem('syncaida-token');
    document.location.href = '/';
  }, []);

  return <>Logging out ...</>;
}

export default Logout;
