import React, { useState } from 'react';
import parse from 'html-react-parser';
import { LwsSelect, LwsTextArea } from '../components/BasicComponents';
import { LwsCard } from '../components/ContainerComponents';

function PsoTextSimulator() {
  const [inputText, setInputText] = useState('');
  const [outputText, setOutPutText] = useState('Output Here');
  const [parseHtml, setParseHtml] = useState(false);
  const [boxClass, setBoxClass] = useState('--message-box');

  const processText = e => {
    const input = e.target.value;
    const output = input.split('<cr>').join('<br/>');
    const shouldParse = input.includes('<cr>');
    setInputText(input);
    setOutPutText(output);
    setParseHtml(shouldParse);
  };

  return (
    <div className='pso-text-simulator'>
      <h1>PSO Text Simulator</h1>
      <LwsCard className='full-width width-sm-max' style={{ padding: '32px' }}>
        <LwsSelect value={boxClass} className='full-width' onChange={e => setBoxClass(e.target.value)}>
          <option style={{ color: '#333' }} value='--message-box'>
            NPC Message
          </option>
          <option style={{ color: '#333' }} value='--window-message'>
            Window Message
          </option>
        </LwsSelect>
        <LwsTextArea value={inputText} className='full-width' rows='3' onChange={processText} />
        <span style={{ textAlign: 'center', fontSize: '.8rem' }}>{'<cr> = line break'}</span>
      </LwsCard>
      <div className={`text-box ${boxClass}`}>
        <p>{parseHtml ? parse(outputText) : outputText}</p>
      </div>
    </div>
  );
}

export default PsoTextSimulator;
