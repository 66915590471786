/* eslint-disable react/no-unknown-property */
/* eslint-disable no-unused-vars */

import React, { useEffect, useState } from 'react';
import { OrbitControls } from '@react-three/drei';
import { Canvas, useThree } from '@react-three/fiber';
import { Plane, Cube } from '../../components/CanvasComponents';
import { Physics } from '@react-three/cannon';

function lerp(start, end, amt) {
  return (1 - amt) * start + amt * end;
}

function clamp(value, min, max) {
  return Math.min(Math.max(value, min), max);
}

export default function D6Roller() {
  const [boxWidth, setBoxWidth] = useState(10);
  const [boxDepth, setBoxDepth] = useState(20);
  const [boxHeight, setBoxHeight] = useState(4);
  const [diceNumInput, setDiceNumInput] = useState(8);
  const [dice, setDice] = useState([]);
  const [dieColor, setDieColor] = useState('#aa55aa');
  const [floorColor, seFloorColor] = useState('#220033');

  function getDice(nDice = 8) {
    const returnDice = [];
    const maxSize = 2.5;
    const minSize = 0.5;
    const maxNDice = 100;
    const minNDice = 4;
    const sizeModifier = 0.5;
    const size = (maxSize - lerp(1, maxSize - minSize, clamp((nDice - minNDice) / maxNDice, 0, 1))) * sizeModifier;
    const spread = Math.max(boxWidth, boxDepth);

    for (let i = 0; i < nDice; i++) {
      const curStep = (i % 4) + 1;

      const randX = Math.random() * size * 2 - size;
      const randY = clamp(Math.random() * boxHeight, size, boxHeight - size);
      const randZ = Math.random() * size * 2 - size;

      const randWidth = (Math.random() * boxWidth - boxWidth / 2) * 0.9;
      const randHeight = (Math.random() * boxDepth - boxDepth / 2) * 0.9;

      const xPosArr = [randWidth, randWidth, boxWidth / 2 - size * 2, -boxWidth / 2 + size * 2];
      const zPosArr = [boxDepth / 2 - size * 2, -boxDepth / 2 + size * 2, randHeight, randHeight];
      // const colorArr = ['red', 'blue', 'yellow', 'green']; // debugging
      const curX = xPosArr[curStep - 1] + randX;
      const curZ = zPosArr[curStep - 1] + randZ;

      returnDice.push(
        <Cube
          color={dieColor}
          spread={spread / 2}
          key={`cube-${i}`}
          args={[size, size, size]}
          position={[curX, randY, curZ]}
        />
      );
    }
    return returnDice;
  }

  return (
    <div style={{ display: 'flex', flexDirection: 'column' }}>
      <Canvas shadows camera={{ position: [0, 20, 0], fov: 45 }}>
        <pointLight
          position={[0, boxHeight * 2, 0]}
          castShadow
          shadow-radius={8}
          shadow-mapSize={[2048, 2048]}
          intensity={1}
        />
        <CanvasInner />
        <OrbitControls target-y={0.5} />
      </Canvas>
      <div style={{ padding: '16px', background: 'black', display: 'flex', gap: '4px' }}>
        <button onClick={() => setDice(getDice(diceNumInput))}>roll</button>
        <input onChange={e => setDiceNumInput(e.target.value)} value={diceNumInput} type='number' />
      </div>
    </div>
  );

  function CanvasInner() {
    const { width, height } = useThree(state => state.viewport);
    const calcBoxWidth = width - 1;
    const calcBoxDepth = height - 1;

    useEffect(() => {
      setBoxWidth(calcBoxWidth);
      setBoxDepth(calcBoxDepth);
    }, []);

    return (
      <>
        <Physics>
          {/* ===================== Invisible bounding box ===================== */}
          {/* bottom */}
          <Plane args={[boxWidth, boxDepth]} color={floorColor} opacity={1} rotation={[-Math.PI / 2, 0, 0]} />
          {/* top */}
          <Plane args={[boxWidth, boxDepth]} position={[0, boxHeight, 0]} rotation={[Math.PI / 2, 0, 0]} />
          {/* right */}
          <Plane
            args={[boxDepth, boxHeight]}
            rotation={[0, -Math.PI / 2, 0]}
            // color='green'
            // opacity={.2}
            position={[boxWidth / 2, boxHeight / 2, 0]}
          />
          {/* left */}
          <Plane
            args={[boxDepth, boxHeight]}
            rotation={[0, Math.PI / 2, 0]}
            // color='red'
            // opacity={.2}
            position={[-boxWidth / 2, boxHeight / 2, 0]}
          />
          {/* back */}
          <Plane
            args={[boxWidth, boxHeight]}
            rotation={[0, 0, 0]}
            // color='blue'
            // opacity={.2}
            position={[0, boxHeight / 2, -boxDepth / 2]}
          />
          {/* front */}
          <Plane
            args={[boxWidth, boxHeight]}
            rotation={[0, Math.PI, 0]}
            // color='yellow'
            // opacity={.2}
            position={[0, boxHeight / 2, boxDepth / 2]}
          />
          {/* ===================== Invisible bounding box ===================== */}
          {dice}
        </Physics>
      </>
    );
  }
}
