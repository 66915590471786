import React, { useState, useEffect } from 'react';
import { LwsCard, LwsCardBody, LwsCardFooter } from '../components/ContainerComponents';
import { LwsButton, LwsLabeledInput } from '../components/BasicComponents';
import { strapiPublicRequest } from '../utils/apiHelper';
import { useSearchParams } from 'react-router-dom';

function ResetPassword() {
  const [searchParams] = useSearchParams();
  const [code, setCode] = useState('');
  const [password, setPassword] = useState('');
  const [passwordConfirmation, setPasswordConfirmation] = useState('');
  const [error, setError] = useState('');
  const [success, setSuccess] = useState('');

  useEffect(() => {
    setCode(searchParams.get('code'));
  }, []);

  const attemptLogin = async () => {
    try {
      setSuccess('');
      const res = await strapiPublicRequest('auth/reset-password', 'post', { code, password, passwordConfirmation });
      if (res?.error) {
        throw new Error(res.error.message);
      }
      setError('');
      setSuccess('Password updated. Return to login to use new password.');
    } catch (err) {
      console.error(err);
      setError(err.message);
      setSuccess('');
    }
  };

  return (
    <div>
      <h1>Reset Password</h1>
      <div className='center-flex'>
        <LwsCard className='full-width width-sm-max'>
          <LwsCardBody>
            {error !== '' ? <p className='card__error'>{error}</p> : null}
            {success !== '' ? <p className='card__success'>{success}</p> : null}
            <LwsLabeledInput
              label='Password'
              name='password'
              type='password'
              value={password}
              onChange={e => setPassword(e.target.value)}
            />
            <LwsLabeledInput
              label='Confirm Password'
              name='passwordConfirmation'
              type='password'
              value={passwordConfirmation}
              onChange={e => setPasswordConfirmation(e.target.value)}
            />
          </LwsCardBody>
          <LwsCardFooter>
            <LwsButton onClick={attemptLogin}>Reset Password</LwsButton>
          </LwsCardFooter>
        </LwsCard>
      </div>
    </div>
  );
}

export default ResetPassword;
