/* eslint-disable no-unused-vars */
import React, { useEffect, useState } from 'react';
import { BiPencil, BiPlus, BiMinus } from 'react-icons/bi';
import { getImageForPlatform } from './api';
import AddEditCharacterModal from './AddEditCharacterModal';
import { useNavigate, useParams } from 'react-router-dom';
import { fetchCharacter, fetchClasses, fetchSectionIds, updateCharacter } from './api';
import MagTracker from './MagTracker';

export default function PSOSelectedCharacterView() {
  // #region consts
  const [sectionIds, setSectionIds] = useState();
  const [classes, setClasses] = useState();
  const [loading, setLoading] = useState(true);

  const [isEditingOpen, setIsEditingOpen] = useState(false);
  const [needsSaving, setNeedsSaving] = useState(false);

  const [character, setcharacter] = useState();
  const [tempPowerUsed, setTempPowerUsed] = useState(0);
  const [tempDefenseUsed, setTempDefenseUsed] = useState(0);
  const [tempEvadeUsed, setTempEvadeUsed] = useState(0);
  const [tempMindUsed, setTempMindUsed] = useState(0);
  const [tempLuckUsed, setTempLuckUsed] = useState(0);
  const [tempHpUsed, setTempHpUsed] = useState(0);
  const [tempTpUsed, setTempTpUsed] = useState(0);
  const [tempLevel, setTempLevel] = useState(1);
  const [tempMagPlan, setTempMagPlan] = useState([]);
  const [totalMaterialsUsed, setTotalMaterialsUsed] = useState(0);
  const [totalMaterialsPlanned, setTotalMaterialsPlanned] = useState(0);

  const { id } = useParams();
  const navigate = useNavigate();

  //#endregion

  //#region Hooks
  async function fetchData() {
    try {
      const [characterRes, sectionIdsRes, classesRes] = await Promise.all([
        fetchCharacter(id),
        fetchSectionIds(),
        fetchClasses(),
      ]);

      if (characterRes) {
        const matsPlanned =
          characterRes.materials_plan.defense_materials_planned +
          characterRes.materials_plan.evade_materials_planned +
          characterRes.materials_plan.power_materials_planned +
          characterRes.materials_plan.luck_materials_planned +
          characterRes.materials_plan.mind_materials_planned;
        setTotalMaterialsPlanned(matsPlanned);
        setTempPowerUsed(parseInt(characterRes.materials_plan.power_materials_used ?? 0));
        setTempDefenseUsed(parseInt(characterRes.materials_plan.defense_materials_used ?? 0));
        setTempEvadeUsed(parseInt(characterRes.materials_plan.evade_materials_used ?? 0));
        setTempMindUsed(parseInt(characterRes.materials_plan.mind_materials_used ?? 0));
        setTempLuckUsed(parseInt(characterRes.materials_plan.luck_materials_used ?? 0));
        setTempHpUsed(parseInt(characterRes.materials_plan.hp_materials_used ?? 0));
        setTempTpUsed(parseInt(characterRes.materials_plan.tp_materials_used ?? 0));
        setTempLevel(parseInt(characterRes.level ?? 1));
        setTempMagPlan(characterRes.mag_plan);

        setcharacter(characterRes);
        setSectionIds(sectionIdsRes);
        setClasses(classesRes);
        setLoading(false);
      } else {
        navigate('/404');
      }
    } catch (err) {
      console.error(err);
    }
  }

  useEffect(() => {
    if (!localStorage.getItem('syncaida-token')) {
      navigate('/login');
      return;
    }

    fetchData();
  }, []);

  async function saveCharacter(newcharacter) {
    await updateCharacter(newcharacter);
    setNeedsSaving(false);
  }

  useEffect(() => {
    const newcharacter = {
      ...character,
      level: tempLevel,
      materials_plan: {
        ...character?.materials_plan,
        power_materials_used: tempPowerUsed,
        defense_materials_used: tempDefenseUsed,
        evade_materials_used: tempEvadeUsed,
        mind_materials_used: tempMindUsed,
        luck_materials_used: tempLuckUsed,
        hp_materials_used: tempHpUsed,
        tp_materials_used: tempTpUsed,
      },
    };
    setcharacter(newcharacter);
    setTotalMaterialsUsed(
      parseInt(tempPowerUsed) +
        parseInt(tempDefenseUsed) +
        parseInt(tempEvadeUsed) +
        parseInt(tempMindUsed) +
        parseInt(tempLuckUsed)
    );

    const interval = setTimeout(() => {
      if (needsSaving) {
        saveCharacter(newcharacter);
      }
    }, 1000);
    return () => clearTimeout(interval);
  }, [needsSaving]);

  //#endregion

  //#region  Event Handlers

  const onDelete = () => {
    navigate('/pso-character-tracker');
  };

  const onEdit = () => {
    setLoading(true);
    fetchData();
  };

  const changePowerUsed = value => {
    setNeedsSaving(true);
    setTempPowerUsed(Math.max(0, tempPowerUsed + value, 0));
  };

  const changeDefenseUsed = value => {
    setNeedsSaving(true);
    setTempDefenseUsed(Math.max(0, tempDefenseUsed + value, 0));
  };

  const changeEvadeUsed = value => {
    setNeedsSaving(true);
    setTempEvadeUsed(Math.max(tempEvadeUsed + value, 0));
  };

  const changeMindUsed = value => {
    setNeedsSaving(true);
    setTempMindUsed(Math.max(tempMindUsed + value, 0));
  };

  const changeLuckUsed = value => {
    setNeedsSaving(true);
    setTempLuckUsed(Math.max(tempLuckUsed + value, 0));
  };

  const changeHpUsed = value => {
    setNeedsSaving(true);
    setTempHpUsed(Math.max(tempHpUsed + value, 0));
  };

  const changeTpUsed = value => {
    setNeedsSaving(true);
    setTempTpUsed(Math.max(tempTpUsed + value, 0));
  };

  const changeLevel = value => {
    setNeedsSaving(true);
    setTempLevel(Math.min(Math.max(tempLevel + value, 1), 200));
  };

  function getProgressColor(tempValue, goal) {
    return tempValue >= goal ? ' --complete' : '';
  }

  async function saveMag(newMag, i) {
    const newMagPlan = [...character.mag_plan];
    newMagPlan[i] = newMag;
    await saveCharacter({ ...character, mag_plan: newMagPlan });
    setTempMagPlan(newMagPlan);
  }

  async function addNewMag() {
    const newMag = {
      mag_name: 'Mag',
      def_current: 5,
      def_planned: 5,
      pow_current: 0,
      pow_planned: 0,
      dex_current: 0,
      dex_planned: 0,
      mind_current: 0,
      mind_planned: 0,
      notes: '',
    };
    const newMagPlan = [...tempMagPlan, newMag];
    await saveCharacter({ ...character, mag_plan: newMagPlan });
    setTempMagPlan(newMagPlan);
  }

  async function deleteMag(i) {
    if (confirm('Are you sure you want to delete this mag?')) {
      const newMagPlan = [...tempMagPlan];
      newMagPlan.splice(i, 1);
      await saveCharacter({ ...character, mag_plan: newMagPlan });
      onEdit();
    }
  }

  //#endregion
  return (
    <>
      <div className='pso-character-tracker-wrapper'>
        <h1>PSO Character Tracker</h1>
        {loading ? (
          <div>Loading...</div>
        ) : (
          <>
            {isEditingOpen && (
              <AddEditCharacterModal
                selectedCharacterObject={character}
                sectionIds={sectionIds}
                classes={classes}
                visible={true}
                setIsOpen={setIsEditingOpen}
                onDelete={onDelete}
                onEdit={onEdit}
              />
            )}
            <div className='pso-character-selected-wrapper'>
              <a
                className='pso-button'
                style={{ padding: '4px 16px', whiteSpace: 'nowrap', color: 'white', textDecoration: 'none' }}
                href='/pso-character-tracker'
                disabled={needsSaving}
              >
                <span style={{ fontSize: '24px' }}>Character Select</span>
              </a>
              <div
                className='pso-character-card'
                style={{ display: 'flex', flexDirection: 'column', alignItems: 'center', maxWidth: '600px' }}
              >
                {needsSaving && (
                  <span
                    style={{
                      color: 'MediumSeaGreen',
                      position: 'absolute',
                      top: '4px',
                      left: '50%',
                      fontSize: '18px',
                      transform: 'translateX(-50%)',
                    }}
                  >
                    Saving...
                  </span>
                )}
                <div className='pso-character-header'>
                  {character.pso_section_id?.image && (
                    <img
                      style={{ filter: 'drop-shadow(0 0 2px black)' }}
                      height='30px'
                      src={character.pso_section_id.image.url}
                    />
                  )}
                  <h3 style={{ width: '100%', textAlign: 'center', margin: 0 }}>{character.name}</h3>
                  <button
                    style={{ justifySelf: 'flex-end' }}
                    disabled={needsSaving}
                    className='pso-button'
                    onClick={() => setIsEditingOpen(true)}
                  >
                    <BiPencil size='24px' color='white' />
                  </button>
                  <span>{character.pso_class.name}</span>
                  <div
                    style={{
                      width: '100%',
                      display: 'flex',
                      justifyContent: 'center',
                      alignItems: 'center',
                      gap: '8px',
                    }}
                  >
                    <button className='pso-button' disabled={tempLevel <= 1} onClick={() => changeLevel(-1)}>
                      <BiMinus size='18px' />
                    </button>
                    <span>Lv{tempLevel}</span>
                    <button className='pso-button' disabled={tempLevel >= 200} onClick={() => changeLevel(1)}>
                      <BiPlus size='18px' />
                    </button>
                  </div>
                  {character.platform !== 'none' && (
                    <img
                      src={getImageForPlatform(character.platform)}
                      alt={character.platform}
                      style={{
                        alignSelf: 'center',
                        justifySelf: 'flex-end',
                        maxHeight: '40px',
                        filter: 'drop-shadow(0 0 2px black)',
                      }}
                    />
                  )}
                </div>

                <hr className='pso-divider' />
                <div
                  className={`pso-character-line${getProgressColor(
                    tempHpUsed,
                    character.pso_class.materials.max_hp_materials
                  )}`}
                >
                  <span>HP Used:</span>
                  <div className='pso-mats-controls'>
                    <button className='pso-button' disabled={tempHpUsed <= 0} onClick={() => changeHpUsed(-1)}>
                      <BiMinus size='24px' />
                    </button>
                    <span>
                      {tempHpUsed} / {character.pso_class.materials.max_hp_materials}
                    </span>
                    <button className='pso-button' onClick={() => changeHpUsed(1)}>
                      <BiPlus size='24px' />
                    </button>
                  </div>
                </div>
                {character.pso_class.materials.max_tp_materials > 0 && (
                  <div
                    className={`pso-character-line${getProgressColor(
                      tempTpUsed,
                      character.pso_class.materials.max_tp_materials
                    )}`}
                  >
                    <span>TP Used:</span>
                    <div className='pso-mats-controls'>
                      <button className='pso-button' disabled={tempTpUsed <= 0} onClick={() => changeTpUsed(-1)}>
                        <BiMinus size='24px' />
                      </button>
                      <span>
                        {tempTpUsed} / {character.pso_class.materials.max_tp_materials}
                      </span>
                      <button className='pso-button' onClick={() => changeTpUsed(1)}>
                        <BiPlus size='24px' />
                      </button>
                    </div>
                  </div>
                )}
                <div
                  className={`pso-character-line${getProgressColor(
                    tempPowerUsed,
                    character.materials_plan.power_materials_planned
                  )}`}
                >
                  <span>Power Used:</span>
                  <div className='pso-mats-controls'>
                    <button className='pso-button' disabled={tempPowerUsed <= 0} onClick={() => changePowerUsed(-1)}>
                      <BiMinus size='24px' />
                    </button>
                    <span>
                      {tempPowerUsed} / {character.materials_plan.power_materials_planned}
                    </span>
                    <button className='pso-button' onClick={() => changePowerUsed(1)}>
                      <BiPlus size='24px' />
                    </button>
                  </div>
                </div>
                <div
                  className={`pso-character-line${getProgressColor(
                    tempDefenseUsed,
                    character.materials_plan.defense_materials_planned
                  )}`}
                >
                  <span>Defense Used:</span>
                  <div className='pso-mats-controls'>
                    <button
                      className='pso-button'
                      disabled={tempDefenseUsed <= 0}
                      onClick={() => changeDefenseUsed(-1)}
                    >
                      <BiMinus size='24px' />
                    </button>
                    <span>
                      {tempDefenseUsed} / {character.materials_plan.defense_materials_planned}
                    </span>
                    <button className='pso-button' onClick={() => changeDefenseUsed(1)}>
                      <BiPlus size='24px' />
                    </button>
                  </div>
                </div>
                <div
                  className={`pso-character-line${getProgressColor(
                    tempEvadeUsed,
                    character.materials_plan.evade_materials_planned
                  )}`}
                >
                  <span>Evade Used:</span>
                  <div className='pso-mats-controls'>
                    <button className='pso-button' disabled={tempEvadeUsed <= 0} onClick={() => changeEvadeUsed(-1)}>
                      <BiMinus size='24px' />
                    </button>
                    <span>
                      {tempEvadeUsed} / {character.materials_plan.evade_materials_planned}
                    </span>
                    <button className='pso-button' onClick={() => changeEvadeUsed(1)}>
                      <BiPlus size='24px' />
                    </button>
                  </div>
                </div>
                <div
                  className={`pso-character-line${getProgressColor(
                    tempMindUsed,
                    character.materials_plan.mind_materials_planned
                  )}`}
                >
                  <span>Mind Used:</span>
                  <div className='pso-mats-controls'>
                    <button className='pso-button' disabled={tempMindUsed <= 0} onClick={() => changeMindUsed(-1)}>
                      <BiMinus size='24px' />
                    </button>
                    <span>
                      {tempMindUsed} / {character.materials_plan.mind_materials_planned}
                    </span>
                    <button className='pso-button' onClick={() => changeMindUsed(1)}>
                      <BiPlus size='24px' />
                    </button>
                  </div>
                </div>
                <div
                  className={`pso-character-line${getProgressColor(
                    tempLuckUsed,
                    character.materials_plan.luck_materials_planned
                  )}`}
                >
                  <span>Luck Used:</span>
                  <div className='pso-mats-controls'>
                    <button className='pso-button' disabled={tempLuckUsed <= 0} onClick={() => changeLuckUsed(-1)}>
                      <BiMinus size='24px' />
                    </button>
                    <span>
                      {tempLuckUsed} / {character.materials_plan.luck_materials_planned}
                    </span>
                    <button className='pso-button' onClick={() => changeLuckUsed(1)}>
                      <BiPlus size='24px' />
                    </button>
                  </div>
                </div>
                <hr className='pso-divider' />
                <div
                  className={`pso-character-line${
                    totalMaterialsPlanned === character.pso_class.materials.max_stat_materials
                      ? ' --complete'
                      : totalMaterialsPlanned > character.pso_class.materials.max_stat_materials
                      ? ' --over'
                      : ''
                  }`}
                >
                  <span>Mats. Planned:</span>
                  <span>
                    {totalMaterialsPlanned} / {character.pso_class.materials.max_stat_materials}
                  </span>
                </div>
                <div
                  className={`pso-character-line${
                    totalMaterialsUsed === character.pso_class.materials.max_stat_materials
                      ? ' --complete'
                      : totalMaterialsUsed > character.pso_class.materials.max_stat_materials
                      ? ' --over'
                      : ''
                  }`}
                >
                  <span>Total Used:</span>
                  <span>
                    {totalMaterialsUsed} / {character.pso_class.materials.max_stat_materials}
                  </span>
                </div>
              </div>
              {tempMagPlan.map((plan, i) => (
                <MagTracker key={`mag-${i}`} mag={plan} saveMag={saveMag} deleteMag={deleteMag} index={i} />
              ))}
              <button className='pso-character-card' style={{ maxWidth: '600px' }} onClick={() => addNewMag()}>
                <BiPlus
                  size='50px'
                  color='white'
                  style={{ filter: 'drop-shadow(0 0 2px black)', gridColumn: '1/-1', justifySelf: 'center' }}
                />
                <span style={{ gridColumn: '1/-1', justifySelf: 'center' }}>New Mag</span>
              </button>
            </div>
          </>
        )}
      </div>
    </>
  );
}
