import React, { useState, useEffect } from 'react';
import { Routes, Route, Link } from 'react-router-dom';
import Home from './views/Home';
import Login from './views/Login';
import About from './views/About';
import D6Roller from './views/D6Roller';
import ForgotPassword from './views/ForgotPassword';
import ResetPassword from './views/ResetPassword';
import Budgeter from './views/Budgeter/Budgeter';
import PsoTextSimulator from './views/PsoTextSimulator';
import NotFound from './views/NotFound';
import NYTripMap from './views/NYTripMap';
import 'mapbox-gl/dist/mapbox-gl.css';
import { FaMapMarkedAlt, FaDiceFive, FaFileInvoiceDollar } from 'react-icons/fa';
import { GiCigale, GiStarSattelites } from 'react-icons/gi';
import { VscHubot } from 'react-icons/vsc';
import { RiLoginCircleLine, RiLogoutCircleLine, RiSettings5Fill } from 'react-icons/ri';

import Logout from './views/Logout';
import PSOCharacterListView from './views/PsoCharacterTracker/PSOCharacterListView';
import PSOSelectedCharacterView from './views/PsoCharacterTracker/PSOSelectedCharacterView';
import UserSettings from './views/UserSettings';

function App() {
  const [navOpen, setNavOpen] = useState(false);
  const [basePath, setBasePath] = useState(null);
  /* routes object
    {
      route: string,
      label: string,
      icon: icon,
      component: component,
      requiresAuth?: boolean,
      isDivider?: boolean,
      isHidden?: boolean
    }
  */
  const routes = [
    { route: '/', label: 'Home', icon: <i className='fa-solid fa-house'></i>, component: <Home /> },
    { route: '/about', label: 'About Me', icon: <i className='fa-solid fa-circle-question'></i>, component: <About /> },
    { isDivider: true },
    {
      route: '/ny-map',
      label: 'NY Trip Map',
      icon: <FaMapMarkedAlt />,
      component: <NYTripMap />,
      isHidden: true,
    },
    {
      route: '/pso-text-simulator',
      label: 'PSO Text Simulator',
      icon: <VscHubot />,
      component: <PsoTextSimulator />,
    },
    {
      route: '/d6-roller',
      label: 'D6 Roller',
      icon: <FaDiceFive />,
      component: <D6Roller />,
      requiresAuth: true,
    },
    { isDivider: true, requiresAuth: true },
    {
      route: '/budgeter',
      label: 'Budgeter',
      icon: <FaFileInvoiceDollar />,
      component: <Budgeter />,
      requiresAuth: true,
      isHidden: true,
    },
    {
      route: '/pso-character-tracker',
      label: 'PSO Character Tracker',
      icon: <GiStarSattelites />,
      component: <PSOCharacterListView />,
      requiresAuth: true,
    },
    {
      route: '/syncaida-db',
      label: 'Syncaida Database',
      icon: <GiCigale />,
      component: <div>Coming Soon</div>,
      requiresAuth: true,
      isHidden: true,
    },
  ];

  const userRoutes = [
    {
      route: '/user-settings',
      label: 'User Settings',
      icon: <RiSettings5Fill />,
      component: <UserSettings />,
      isHidden: !localStorage.getItem('syncaida-token'),
    },
    {
      route: localStorage.getItem('syncaida-token') ? '/logout' : '/login',
      label: localStorage.getItem('syncaida-token') ? 'Logout' : 'Login',
      icon: localStorage.getItem('syncaida-token') ? <RiLogoutCircleLine /> : <RiLoginCircleLine />,
      component: localStorage.getItem('syncaida-token') ? <Logout /> : <Login />,
    },
  ];

  useEffect(() => {
    const pathname = window.location.pathname;
    const splitPath = pathname.split('/');
    setBasePath(splitPath[1]);
  }, []);

  const getActiveRoute = path => {
    return path === basePath ? ' --active' : '';
  };

  const toggleNav = () => {
    setNavOpen(!navOpen);
  };

  const closeNav = () => {
    setNavOpen(false);
  };

  const hasToken = () => {
    const token = localStorage.getItem('syncaida-token');
    return !!token;
  };

  const itemShouldShow = item => {
    const authPass = !item.requiresAuth || (item.requiresAuth && hasToken());
    return !item.isHidden && authPass;
  };

  return (
    <div className={`App${navOpen ? ' --nav-open' : ''}`}>
      <nav>
        <button className='nav-link' onClick={toggleNav}>
          <div className='nav-link__icon'>
            {navOpen ? <i className='fa-solid fa-xmark'></i> : <i className='fa-solid fa-bars'></i>}
          </div>
          <span className='nav-link__label'>{navOpen ? 'Close' : 'Open'} Menu</span>
        </button>
        <hr />
        <div className='nav-inner'>
          {routes.map(item =>
            itemShouldShow(item) ? (
              item.isDivider ? (
                <hr key={Math.random() + 'divider'} style={{ margin: '0' }} />
              ) : (
                <Link
                  to={item.route}
                  onClick={() => {
                    closeNav();
                    setBasePath(item.route.replace('/', ''));
                  }}
                  className={'nav-link' + getActiveRoute(item.route.replace('/', ''))}
                  key={item.route.replace('/', '') + '-nav-item'}
                >
                  <div className='nav-link__icon'>{item.icon}</div>
                  <span className='nav-link__label'>{item.label}</span>
                </Link>
              )
            ) : null
          )}
        </div>
        <hr />
        <div className='spacer'>
          <div className='nav-inner'>
            {userRoutes.map(item =>
              itemShouldShow(item) ? (
                item.isDivider ? (
                  <hr key={Math.random() + 'divider'} style={{ margin: '0' }} />
                ) : (
                  <Link
                    to={item.route}
                    onClick={() => {
                      closeNav();
                      setBasePath(item.route.replace('/', ''));
                    }}
                    className={'nav-link' + getActiveRoute(item.route.replace('/', ''))}
                    key={item.route.replace('/', '') + '-nav-item'}
                  >
                    <div className='nav-link__icon'>{item.icon}</div>
                    <span className='nav-link__label'>{item.label}</span>
                  </Link>
                )
              ) : null
            )}
          </div>
        </div>
      </nav>
      <main>
        <Routes>
          {routes.map(item =>
            item.isDivider ? null : (
              <Route path={item.route} element={item.component} key={item.route.replace('/', '') + '-route'} />
            )
          )}
          {userRoutes.map(item =>
            item.isDivider ? null : (
              <Route path={item.route} element={item.component} key={item.route.replace('/', '') + '-route'} />
            )
          )}
          <Route path='/pso-character-tracker/:id' element={<PSOSelectedCharacterView />} />
          <Route path='/forgot-password' element={<ForgotPassword />} />
          <Route path='/reset-password' element={<ResetPassword />} />
          <Route path='*' element={<NotFound />} />
        </Routes>
      </main>
    </div>
  );
}

export default App;
