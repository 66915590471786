/* eslint-disable quotes */
const letterValues = {
  A: 5,
  B: 6,
  C: 7,
  D: 8,
  E: 9,
  F: 0,
  G: 1,
  H: 2,
  I: 3,
  J: 4,
  K: 5,
  L: 6,
  M: 7,
  N: 8,
  O: 9,
  P: 0,
  Q: 1,
  R: 2,
  S: 3,
  T: 4,
  U: 5,
  V: 6,
  W: 7,
  X: 8,
  Y: 9,
  Z: 0,
  a: 7,
  b: 8,
  c: 9,
  d: 0,
  e: 1,
  f: 2,
  g: 3,
  h: 4,
  i: 5,
  j: 6,
  k: 7,
  l: 8,
  m: 9,
  n: 0,
  o: 1,
  p: 2,
  q: 3,
  r: 4,
  s: 5,
  t: 6,
  u: 7,
  v: 8,
  w: 9,
  x: 0,
  y: 1,
  z: 2,
  1: 9,
  2: 0,
  3: 1,
  4: 2,
  5: 3,
  6: 4,
  7: 5,
  8: 6,
  9: 7,
  0: 8,
  '`': 9,
  '~': 6,
  '!': 3,
  '@': 4,
  '#': 5,
  $: 6,
  '%': 7,
  '^': 4,
  '&': 8,
  '*': 2,
  '(': 0,
  ')': 1,
  '-': 5,
  _: 5,
  '=': 1,
  '+': 3,
  '\\': 2,
  '|': 5,
  '[': 1,
  '{': 3,
  ']': 3,
  '}': 4,
  ';': 9,
  ':': 8,
  "'": 6,
  '"': 4,
  ',': 4,
  '<': 0,
  '.': 6,
  '>': 2,
  '/': 7,
  '?': 3,
  ' ': 2,
};

export function calculateSectionID(name, platform = 'DC', magicNumber = 0) {
  if (name === '') {
    return 0;
  }
  const nameArray = name.split('');
  let sum = 0;
  nameArray.forEach(letter => {
    sum += letterValues[letter];
  });
  if (platform === 'BB') {
    sum += magicNumber;
  }
  const result = Math.floor(sum % 10);
  return result;
}
