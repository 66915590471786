import React, { useState } from 'react';
import { BiRefresh } from 'react-icons/bi';

function App() {
  // eslint-disable-next-line no-unused-vars
  const [videoUrls, setVideoUrls] = useState([
    'https://www.youtube.com/embed/IMKW-ifxikE',
    'https://www.youtube.com/embed/9AMYVgtmkoM',
    'https://www.youtube.com/embed/wAu_fYHZKLs',
    'https://www.youtube.com/embed/Y-dMSstLDqM',
    'https://www.youtube.com/embed/lzmWzXLPa6I',
    'https://www.youtube.com/embed/CMkYw4dp_NI',
    'https://www.youtube.com/embed/xKR4FAg1imc',
    'https://www.youtube.com/embed/1GX69llei1E',
    'https://www.youtube.com/embed/Eni9PPPPBpg',
    'https://www.youtube.com/embed/dbn-QDttWqU',
  ]);
  const [videoUrl, setVideoUrl] = useState(videoUrls[Math.floor(Math.random() * videoUrls.length)]);

  function randomVideo() {
    setVideoUrl(videoUrls[Math.floor(Math.random() * videoUrls.length)]);
  }
  return (
    <>
      <h1>Welcome to my workshop.</h1>
      <div className='home-container'>
        <div style={{ display: 'flex', gap: '32px', marginBottom: '16px' }}>
          <h2>Heres a video I enjoy!</h2>
          <button style={{ background: 'none', border: 'none' }} onClick={() => randomVideo()}>
            <BiRefresh fontSize='40px' color='#d8d8d8' />
          </button>
        </div>
        <iframe
          src={videoUrl}
          title='YouTube video player'
          frameBorder='0'
          allow='accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture'
          allowFullScreen
        ></iframe>
      </div>
    </>
  );
}

export default App;
