import React, { useEffect, useState } from 'react';
import Map, { Marker, NavigationControl } from 'react-map-gl';
import { MdCatchingPokemon } from 'react-icons/md';
import { IoMdPizza } from 'react-icons/io';
import { HiLibrary } from 'react-icons/hi';
import { FaHotel, FaQuestion } from 'react-icons/fa';
import { AiFillShop } from 'react-icons/ai';
import { BsAirplaneFill } from 'react-icons/bs';
import mapboxgl from 'mapbox-gl';

// The following is required to stop "npm build" from transpiling mapbox code.
// notice the exclamation point in the import.
import Worker from 'worker-loader!mapbox-gl/dist/mapbox-gl-csp-worker';
import { strapiPublicRequest } from '../utils/apiHelper';
const onLocal = location.hostname === 'localhost' || location.hostname === '127.0.0.1';
if (!onLocal) {
  mapboxgl.workerClass = Worker;
}

function NYTripMap() {
  const mapToken = onLocal
    ? 'pk.eyJ1IjoicGFzY2FscGFuZGEiLCJhIjoiY2xoOWY0ZmhlMDJnNjNxbnFqaW1jYnowbCJ9.kMy0Dj7U8dR-awHm-jrBbA'
    : 'pk.eyJ1IjoicGFzY2FscGFuZGEiLCJhIjoiY2xoOWpkb3luMDgwMzNnb2dvZzJjMGMzayJ9.QDqgAVD877bxWv5V6cLrtw';
  const mapStyle = 'mapbox://styles/pascalpanda/clh9f84nv006601nx7edp5u70';
  const initialViewState = { latitude: 40.7128, longitude: -74.006, zoom: 10 };
  const [viewState, setViewState] = useState(initialViewState);
  const [markers, setMarkers] = useState([]);
  const [locations, setLocations] = useState([]);

  useEffect(() => {
    async function getDataFromApi() {
      const newLocations = [];
      const locationRes = await strapiPublicRequest('ny-map-locations');
      for (const location of locationRes) {
        location.hidden = true;
        newLocations.push(location);
      }
      setLocations(newLocations);
    }
    getDataFromApi();
  }, []);

  const MarkerIcon = ({ location }) => {
    switch (location.type) {
      case 'pizza':
        return (
          <IoMdPizza
            fontSize='18px'
            color={location.hidden ? '#333333' : '#f97316'}
            style={{ transition: 'ease all .35s' }}
          />
        );
      case 'store':
        return (
          <AiFillShop
            fontSize='18px'
            color={location.hidden ? '#333333' : '#15803d'}
            style={{ transition: 'ease all .35s' }}
          />
        );
      case 'attraction':
        return (
          <MdCatchingPokemon
            fontSize='24px'
            color={location.hidden ? '#333333' : '#b91c1c'}
            style={{ transition: 'ease all .35s' }}
          />
        );
      case 'hotel':
        return (
          <FaHotel
            fontSize='14px'
            color={location.hidden ? '#333333' : '#7e22ce'}
            style={{ transition: 'ease all .35s' }}
          />
        );
      case 'airport':
        return (
          <BsAirplaneFill
            fontSize='16px'
            color={location.hidden ? '#333333' : '#2563eb'}
            style={{ transition: 'ease all .35s' }}
          />
        );
      case 'museum':
        return (
          <HiLibrary
            fontSize='16px'
            color={location.hidden ? '#333333' : '#0e7490'}
            style={{ transition: 'ease all .35s' }}
          />
        );
      default:
        return (
          <FaQuestion
            fontSize='20px'
            color={location.hidden ? '#333333' : 'grey'}
            style={{ transition: 'ease all .35s' }}
          />
        );
    }
  };

  useEffect(() => {
    const newLocations = locations.map((location, i) => {
      return (
        <Marker
          key={location.title}
          latitude={location.latitude}
          longitude={location.longitude}
          anchor='center'
          onClick={e => onLocationClicked(e, i)}
          style={{ zIndex: location.hidden ? 0 : 200 }}
        >
          <span className={'ny-map-marker__label' + (location.hidden ? '' : ' --active')}>{location.title}</span>
          <div className={'ny-map-marker__background' + (location.hidden ? '' : ' --active')}>
            <MarkerIcon location={location} />
          </div>
          {location.description && (
            <span className={'ny-map-marker__description' + (location.hidden ? '' : ' --active')}>
              {location.description}
            </span>
          )}
        </Marker>
      );
    });
    setMarkers(newLocations);
  }, [locations]);

  function onLocationClicked(e, i) {
    e.originalEvent.stopPropagation();
    const newLocations = [...locations];
    newLocations[i].hidden = !newLocations[i].hidden;
    setLocations(newLocations);
  }

  function HidePlaces() {
    const newLocations = locations.map(location => {
      return { ...location, hidden: true };
    });
    setLocations(newLocations);
  }

  return (
    <>
      <Map
        {...viewState}
        onMove={evt => setViewState(evt.viewState)}
        mapboxAccessToken={mapToken}
        mapStyle={mapStyle}
        attributionControl={false}
        style={{ width: '100%', height: '100%' }}
        onClick={() => {
          HidePlaces();
        }}
      >
        {markers}
        <NavigationControl />
      </Map>
    </>
  );
}

export default NYTripMap;
