import React from 'react';

function App() {
  return (
    <>
      <h1>About me</h1>
      <div style={{ padding: '0 16px 16px 16px' }}>
        <p className='about-section'>
          <p>
            I am Grayson Lorenz, professional code wizard. I love my work, and on the side I play D&D and have a small
            wood shop business: <a href='https://LorenzWoodShop.com'>Lorenz Wood Shop</a>
          </p>
          <p>
            This website serves as a place for me to put the apps my friends ask me for that I think would be fun, or
            things I use personally. This is where I work on becoming a better developer of tools and UI/UX.
          </p>
        </p>
        <p className='about-section'>
          <h2>Short Story</h2>
          <p>
            I've been a professional developer since 2015 and have been working with Java, C#, MySQL, PostgreSQL,
            MongoDB, Javascript (Node, Express, Vue and React), PHP, HTML and CSS.
          </p>
          <p>
            My current expertise is in CSS, React, PostgreSQL, and Node. I use this for most projects I work on
            professionally and personally, including this site. I have an eye for UI/UX and like rolling my own css.
          </p>
          <p>
            I have worked on EMR (Electronic Medical Records) systems, Restful APIs, magazine content management
            systems, a streaming service for rodeo content, many wordpress sites, and many other things.
          </p>
          <p>
            If you, or your client, can think it, I can build it. Even if it's hard to articulate. My number one
            priority is being a steward of my projects. I care about the success of the spirit of my projects.
          </p>
        </p>

        <p className='about-section'>
          <h2>Longer Story</h2>
          <p>
            I programming in somewhere around 2005 with Gamemaker. In 2006 I started using VB in my first programming
            class, and then in 2007 I moved up to java and made a bunch of small command line apps for classes, and
            personal use.
          </p>
          <p>
            In 2008 I started going to college for music education, but suffice to say the first 2 years of balancing
            college fulltime, work full time, and life full time was not an enjoyable experience.
          </p>
          <p>
            I backed off of the class load heavily, and switched from music education to IT and Networking. I love music
            and play guitar, bass, and saxophone needed a change. I studied IT for 2 semesters and then switched to
            programming for a hand full of part time semesters. I found that IT was not for me, and full of "alphabet
            soup" but I thought this was required for the programing track at the advice of my advisor. During this time
            I started participating in the robotics club in either 2012. This would be the start of "knowing the right
            people" for me.
          </p>
          <p>
            In 2015 I was working at one of the hospitals in my city at the helpdesk, trying to get some vaguely
            computer related experience and programming on the overnight shift on some projects I had going on. One of
            the teachers at my college, who helped with the robotics, was working at an agency that built websites and
            webapps. We had become friends over the 3 years (during which he'd get me into D&D), so when he left he
            recommended I apply and gave me a good recommendation. That turned into my first proper programming job, and
            got me on my way.
          </p>
          <p>
            I'd work this job for 2 years, taking a few classes per semester to get through college without taking other
            loans. By the time I'd worked 2 years I was only about 3/4 way through an actual 2 year degree due to
            switching degrees, and not having great guidance on classes counted toward my degree. Having 2 years work
            experience, and seeing everyone asking for a degree OR equivalent work experience I stopped going to school
            and leaned into my work. I continued to work, meeting people here and there, getting reffered to another
            agency when that agency cut about a third of it's staff due to our local economy taking a massive hit.
          </p>
          <p>
            Ever since then I've been able to keep up my experience, and keep on keeping on. I've been sole developer on
            an EMR project; I've architected many admin portals for applications; I've worked on the fantastic{' '}
            <a href='https://yondar.me'>Yondar.me</a> building a large portion of the backend and architecting most of
            the admin at the time with a fantastic team; And I continue to find amazing projects to be a big part of and
            love what I do.
          </p>
        </p>
      </div>
    </>
  );
}

export default App;
