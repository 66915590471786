/* eslint-disable no-undef */
// import { deserialize, CaseType } from 'jsonapi-fractal';

export const apiRequest = async (url, method = 'get', headers, body = null, query = '') => {
  method = method.toLocaleLowerCase();
  if (url.charAt(0) === '/') {
    url = url.substring(1);
  }

  if (method === 'get' && body) {
    console.warn('GET request with body');
  }

  const constructedUrl = process.env.REACT_APP_API_URL + '/api/' + url + (query ? '?' + query : '');
  const constructedOptions = {
    method: method,
    headers: headers,
    credentials: 'include',
  };
  if (method !== 'get') {
    constructedOptions.body = JSON.stringify(body);
  }
  const res = await fetch(constructedUrl, constructedOptions);
  const resJson = await res.json();
  return resJson;
};

export const strapiPublicRequest = async (url, method = 'get', body = null, query = '') => {
  const bearerToken = `Bearer ${process.env.REACT_APP_STRAPI_TOKEN}`;
  const headers = {
    'Content-Type': 'application/json',
    Authorization: bearerToken,
  };

  return apiRequest(url, method, headers, body, query);
};

export const strapiUserRequest = async (url, method = 'get', body = null, query = '') => {
  const bearerToken = `Bearer ${localStorage.getItem('syncaida-token')}`;
  const headers = {
    'Content-Type': 'application/json',
    Authorization: bearerToken,
  };

  return apiRequest(url, method, headers, body, query);
};
