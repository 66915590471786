import { CaseType, deserialize } from 'jsonapi-fractal';
import { strapiPublicRequest, strapiUserRequest } from '../../utils/apiHelper';
import BlueBurstLogo from '../../images/BlueBurstLogo.png';
import GamecubeLogo from '../../images/GamecubeLogo.png';
import V1Logo from '../../images/V1Logo.png';
import V2Logo from '../../images/V2Logo.png';
import XboxLogo from '../../images/xboxLogo.png';

export async function fetchSectionIds() {
  const res = await strapiPublicRequest('pso-section-ids', 'get', null, 'populate[0]=image');
  const flatRes = res?.data ? deserialize(res, { changeCase: CaseType.camelCase }) : null;
  if (flatRes && flatRes.length > 0) {
    for (const section of flatRes) {
      if (section.image && section.image.data) {
        section.image = deserialize(section.image, { changeCase: CaseType.camelCase });
      }
    }
  }
  return flatRes;
}

export async function fetchClasses() {
  const res = await strapiPublicRequest('pso-classes');
  const flatRes = res?.data ? deserialize(res, { changeCase: CaseType.camelCase }) : null;
  return flatRes;
}

export async function fetchCharacters() {
  return await strapiUserRequest('pso-characters');
}

export async function fetchCharacter(id) {
  const res = await strapiUserRequest(`pso-characters/${id}`);
  return res?.[0];
}

export async function updateCharacter(character) {
  return await strapiUserRequest(`pso-characters/${character.id}`, 'put', character);
}

export async function createCharacter(character) {
  return await strapiUserRequest('pso-characters', 'post', character);
}

export async function deleteCharacter(id) {
  return await strapiUserRequest(`pso-characters/${id}`, 'delete');
}

export function getImageForPlatform(platform) {
  let image = null;
  switch (platform) {
    case 'BB':
      image = BlueBurstLogo;
      break;
    case 'GCN':
      image = GamecubeLogo;
      break;
    case 'V1':
      image = V1Logo;
      break;
    case 'V2':
      image = V2Logo;
      break;
    case 'XBOX':
      image = XboxLogo;
      break;
  }
  return image;
}
