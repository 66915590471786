/* eslint-disable no-unused-vars */
import React, { useState, useEffect } from 'react';
import { LwsCard, LwsCardBody, LwsCardFooter } from '../components/ContainerComponents';
import { LwsButton, LwsLabeledInput } from '../components/BasicComponents';
import { useNavigate } from 'react-router-dom';
import { strapiUserRequest } from '../utils/apiHelper';

export default function UserSettings() {
  const [id, setId] = useState(-1);
  const [username, setUsername] = useState('');
  const [email, setEmail] = useState('');
  const [error, setError] = useState('');
  const [loading, setLoading] = useState(true);
  const [success, setSuccess] = useState('');
  const navigate = useNavigate();

  async function fetchData() {
    try {
      const user = await strapiUserRequest('users/me');
      setId(user.id);
      setEmail(user.email);
      setUsername(user.username);
      setLoading(false);
      setError('');
      setSuccess('');
    } catch (err) {
      console.error(err);
      setError(err.message);
    }
  }

  async function submitUpdate() {
    try {
      setSuccess('');
      await strapiUserRequest(`users/${id}`, 'PUT', {
        username,
        email,
      });
      setError('');
      setSuccess('Successfully updated user settings');
    } catch (err) {
      console.error(err);
      setError(err.message);
      setSuccess('');
    }
  }

  useEffect(() => {
    if (!localStorage.getItem('syncaida-token')) {
      navigate('/login');
      return;
    }
    fetchData();
  }, []);

  return (
    <div>
      <h1>User Settings</h1>
      <div className='center-flex'>
        {loading ? (
          <div>Loading...</div>
        ) : (
          <LwsCard className='full-width width-sm-max'>
            <LwsCardBody>
              {error !== '' ? <p className='card__error'>{error}</p> : null}
              {success !== '' ? <p className='card__success'>{success}</p> : null}
              <LwsLabeledInput
                label='Username'
                name='username'
                value={username}
                onChange={e => setUsername(e.target.value)}
              />
              <LwsLabeledInput label='Email' name='email' value={email} onChange={e => setEmail(e.target.value)} />
              <a href='/forgot-password'>Change Password</a>
            </LwsCardBody>
            <LwsCardFooter>
              <LwsButton onClick={() => submitUpdate()}>Submit</LwsButton>
            </LwsCardFooter>
          </LwsCard>
        )}
      </div>
    </div>
  );
}
