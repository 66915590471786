import React, { useEffect, useState } from 'react';
import { LwsButton, LwsLabeledInput, LwsLabeledSelect } from '../../components/BasicComponents';
import { LwsModal } from '../../components/ContainerComponents';
import { createCharacter, deleteCharacter, getImageForPlatform, updateCharacter } from './api';
import { calculateSectionID } from './calculator';

export default function AddEditCharacterModal({
  selectedCharacterObject,
  sectionIds,
  classes,
  visible,
  setIsOpen,
  onEdit,
  refetchCharacters,
  onDelete = () => {},
  hideDelete = false,
}) {
  // TODO: use new components
  const isEditing = !!selectedCharacterObject;
  const { id, name, level, platform, pso_class, pso_section_id, materials_plan } = selectedCharacterObject ?? {
    id: -1,
    name: '',
    level: 1,
    platform: 'none',
    pso_class: {
      id: -1,
      name: '',
      materials: {
        max_hp_materials: 0,
        max_tp_materials: 0,
        max_stat_materials: 0,
      },
    },
    pso_section_id: {
      id: -1,
      name: '',
      calculation_outcome_value: -1,
    },
    materials_plan: {
      power_materials_planned: 0,
      power_materials_used: 0,
      defense_materials_planned: 0,
      defense_materials_used: 0,
      evade_materials_planned: 0,
      evade_materials_used: 0,
      mind_materials_planned: 0,
      mind_materials_used: 0,
      luck_materials_planned: 0,
      luck_materials_used: 0,
      hp_materials_used: 0,
      hp_materials_planned: 0,
      tp_materials_used: 0,
      tp_materials_planned: 0,
    },
  };

  const [tempName, setTempName] = useState(name);
  const [tempLevel, setTempLevel] = useState(level);
  const [selectedSectionId, setSelectedSectionId] = useState(pso_section_id);
  const [selectedClass, setSelectedClass] = useState(pso_class);
  const [selectedPlatform, setSelectedPlatform] = useState(platform);
  const [submitting, setSubmitting] = useState(false);

  const [tempDefensePlanned, setTempDefensePlanned] = useState(materials_plan.defense_materials_planned);
  const [tempDefenseUsed, setTempDefenseUsed] = useState(materials_plan.defense_materials_used);
  const [tempEvadePlanned, setTempEvadePlanned] = useState(materials_plan.evade_materials_planned);
  const [tempEvadeUsed, setTempEvadeUsed] = useState(materials_plan.evade_materials_used);
  const [tempLuckPlanned, setTempLuckPlanned] = useState(materials_plan.luck_materials_planned);
  const [tempLuckUsed, setTempLuckUsed] = useState(materials_plan.luck_materials_used);
  const [tempMindPlanned, setTempMindPlanned] = useState(materials_plan.mind_materials_planned);
  const [tempMindUsed, setTempMindUsed] = useState(materials_plan.mind_materials_used);
  const [tempPowerPlanned, setTempPowerPlanned] = useState(materials_plan.power_materials_planned);
  const [tempPowerUsed, setTempPowerUsed] = useState(materials_plan.power_materials_used);
  const [tempHpUsed, setTempHpUsed] = useState(materials_plan.hp_materials_used);
  const [tempTpUsed, setTempTpUsed] = useState(materials_plan.tp_materials_used);
  const [totalMaterialsPlanned, setTotalMaterialsPlanned] = useState(0);
  const [totalMaterialsUsed, setTotalMaterialsUsed] = useState(0);

  const getTempCharacter = () => {
    return {
      id,
      name: tempName,
      level: tempLevel,
      platform: selectedPlatform,
      pso_class: selectedClass,
      pso_section_id: selectedSectionId,
      materials_plan: {
        power_materials_planned: tempPowerPlanned,
        power_materials_used: tempPowerUsed,
        defense_materials_planned: tempDefensePlanned,
        defense_materials_used: tempDefenseUsed,
        evade_materials_planned: tempEvadePlanned,
        evade_materials_used: tempEvadeUsed,
        mind_materials_planned: tempMindPlanned,
        mind_materials_used: tempMindUsed,
        luck_materials_planned: tempLuckPlanned,
        luck_materials_used: tempLuckUsed,
        hp_materials_used: tempHpUsed,
        hp_materials_planned: pso_class?.materials.max_hp_materials ?? selectedClass.materials.max_hp_materials ?? 0,
        tp_materials_used: tempTpUsed,
        tp_materials_planned: pso_class?.materials.max_tp_materials ?? selectedClass.materials.max_tp_materials ?? 0,
      },
    };
  };

  useEffect(() => {
    setTotalMaterialsPlanned(
      parseInt(tempPowerPlanned) +
        parseInt(tempDefensePlanned) +
        parseInt(tempEvadePlanned) +
        parseInt(tempMindPlanned) +
        parseInt(tempLuckPlanned)
    );
    setTotalMaterialsUsed(
      parseInt(tempPowerUsed) +
        parseInt(tempDefenseUsed) +
        parseInt(tempEvadeUsed) +
        parseInt(tempMindUsed) +
        parseInt(tempLuckUsed)
    );
  }, [
    tempDefensePlanned,
    tempDefenseUsed,
    tempEvadePlanned,
    tempEvadeUsed,
    tempLuckPlanned,
    tempLuckUsed,
    tempMindPlanned,
    tempMindUsed,
    tempPowerPlanned,
    tempPowerUsed,
    tempHpUsed,
    tempTpUsed,
  ]);

  const onClose = () => {
    //reset all values
    setTempName(name);
    setTempLevel(level);
    setSelectedSectionId(pso_section_id);
    setSelectedClass(pso_class);
    setSelectedPlatform(platform);
    setTempDefensePlanned(materials_plan.defense_materials_planned);
    setTempDefenseUsed(materials_plan.defense_materials_used);
    setTempEvadePlanned(materials_plan.evade_materials_planned);
    setTempEvadeUsed(materials_plan.evade_materials_used);
    setTempLuckPlanned(materials_plan.luck_materials_planned);
    setTempLuckUsed(materials_plan.luck_materials_used);
    setTempMindPlanned(materials_plan.mind_materials_planned);
    setTempMindUsed(materials_plan.mind_materials_used);
    setTempPowerPlanned(materials_plan.power_materials_planned);
    setTempPowerUsed(materials_plan.power_materials_used);
    setTempHpUsed(materials_plan.hp_materials_used);
    setTempTpUsed(materials_plan.tp_materials_used);
    setIsOpen(false);
  };

  const submitCharacter = async e => {
    if (e.preventDefault) e.preventDefault();
    try {
      setSubmitting(true);
      if (isEditing) {
        const res = await updateCharacter(getTempCharacter());
        if (res?.error) {
          alert('Could not edit character. Ensure all fields are filled.');
        } else {
          onEdit();
          onClose();
        }
      } else {
        const res = await createCharacter(getTempCharacter());
        if (res?.error) {
          alert('Could not create character. Ensure all fields are filled.');
        } else {
          await refetchCharacters();
          onClose();
        }
      }
      setSubmitting(false);
    } catch (err) {
      console.error(err);
    }
  };

  const deleteCharacterClicked = async e => {
    if (e.preventDefault) e.preventDefault();
    if (isEditing && confirm('Are you sure you want to delete this character?')) {
      try {
        await deleteCharacter(id);
        onDelete();
        onClose();
      } catch (err) {
        console.error(err);
      }
    }
  };

  function getClassById(id) {
    const ourClass = classes.find(psoClass => psoClass.id + '' === id + '');
    return ourClass;
  }

  function getSectionIdByOutcome(outcome) {
    const ourSectionId = sectionIds.find(sectionId => sectionId.calculationOutcomeValue + '' === outcome + '');
    return ourSectionId;
  }

  function calculateClicked() {
    const outcome = calculateSectionID(tempName, selectedPlatform, selectedClass?.sectionIdModifier ?? 0);
    const sectionId = getSectionIdByOutcome(outcome);
    setSelectedSectionId(sectionId);
  }

  return (
    <LwsModal
      visible={visible}
      onClose={onClose}
      title={`${isEditing ? 'Edit' : 'Create'} Character`}
      footerConfirmLabel='Submit'
      footerDangerLabel={hideDelete ? null : 'Delete'}
      footerConfirmAction={submitCharacter}
      footerDangerAction={hideDelete ? null : deleteCharacterClicked}
      disabled={submitting}
    >
      <form onSubmit={submitCharacter} style={{ display: 'flex', flexDirection: 'column', gap: '16px' }}>
        <LwsLabeledInput
          label='Name'
          name='name'
          type='text'
          value={tempName}
          onChange={e => setTempName(e.target.value.substring(0, selectedPlatform === 'BB' ? 10 : 13))}
        />
        <LwsLabeledInput
          label='Level'
          name='level'
          type='number'
          value={tempLevel}
          min='1'
          max='200'
          step='1'
          onChange={e => setTempLevel(e.target.value)}
        />
        <LwsLabeledSelect
          label='Class'
          value={selectedClass.id}
          onChange={e => setSelectedClass(getClassById(e.target.value))}
        >
          <option style={{ color: '#333' }}>Select Class</option>
          {classes.map(psoClass => (
            <option style={{ color: '#333' }} key={psoClass.name} value={psoClass.id}>
              {psoClass.name}
            </option>
          ))}
        </LwsLabeledSelect>
        <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'center', gap: '8px' }}>
          <LwsLabeledSelect
            label='Platform'
            value={selectedPlatform}
            onChange={e => {
              if (e.target.value === 'BB') {
                setTempName(tempName.substring(0, 10));
              }
              setSelectedPlatform(e.target.value);
            }}
          >
            <option style={{ color: '#333' }} value={'none'}>
              Select Platform
            </option>
            <option style={{ color: '#333' }} value={'BB'}>
              Blue Burst
            </option>
            <option style={{ color: '#333' }} value={'GCN'}>
              Gamecube
            </option>
            <option style={{ color: '#333' }} value={'V1'}>
              V1
            </option>
            <option style={{ color: '#333' }} value={'V2'}>
              V2
            </option>
            <option style={{ color: '#333' }} value={'XBOX'}>
              XBox
            </option>
          </LwsLabeledSelect>
          <div style={{ width: '34px' }}>
            {selectedPlatform !== 'none' && (
              <img
                src={getImageForPlatform(selectedPlatform)}
                alt={selectedPlatform}
                style={{ alignSelf: 'center', maxWidth: '30px' }}
              />
            )}
          </div>
        </div>
        <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'center', gap: '8px' }}>
          <LwsLabeledSelect
            label='Section ID'
            value={selectedSectionId.calculation_outcome_value}
            onChange={e => setSelectedSectionId(getSectionIdByOutcome(e.target.value))}
          >
            <option style={{ color: '#333' }}>Select Id</option>
            {sectionIds.map(secId => (
              <option
                style={{ color: '#333' }}
                key={secId.calculationOutcomeValue}
                value={secId.calculationOutcomeValue}
              >
                {secId.name}
              </option>
            ))}
          </LwsLabeledSelect>
          <div style={{ width: '34px' }}>
            {selectedSectionId !== -1 && selectedSectionId.image?.url && (
              <img
                src={selectedSectionId.image.url}
                alt={selectedSectionId.name}
                style={{ alignSelf: 'center', maxWidth: '30px' }}
              />
            )}
          </div>
        </div>
        <LwsButton onClick={() => calculateClicked()}>Calculate Section ID</LwsButton>
        {isEditing && (
          <>
            <hr className='pso-divider' />
            <div style={{ display: 'grid', gridTemplateColumns: '1fr 1fr', gap: '8px' }}>
              <span style={{ width: '100%', textAlign: 'center' }}>
                Used: {totalMaterialsUsed} / {pso_class.materials.max_stat_materials}
              </span>
              <span style={{ width: '100%', textAlign: 'center' }}>
                Planned: {totalMaterialsPlanned} / {pso_class.materials.max_stat_materials}
              </span>
              <LwsLabeledInput
                label='HP Used'
                name='hp-mats-used'
                type='number'
                value={tempHpUsed}
                min='0'
                step='1'
                onChange={e => setTempHpUsed(e.target.value)}
              />
              <span style={{ margin: 'auto 0' }}> HP Max: {pso_class.materials.max_hp_materials}</span>

              {pso_class.materials.max_tp_materials > 0 && (
                <>
                  <LwsLabeledInput
                    label='TP Used'
                    name='tp-mats-used'
                    type='number'
                    value={tempTpUsed}
                    min='0'
                    step='1'
                    onChange={e => setTempTpUsed(e.target.value)}
                  />
                  <span style={{ margin: 'auto 0' }}> TP Max: {pso_class.materials.max_tp_materials}</span>
                </>
              )}

              <LwsLabeledInput
                label='Power Used'
                name='power-mats-used'
                type='number'
                value={tempPowerUsed}
                min='0'
                step='1'
                onChange={e => setTempPowerUsed(e.target.value)}
              />
              <LwsLabeledInput
                label='Power Plan'
                name='power-mats-planned'
                type='number'
                value={tempPowerPlanned}
                min='0'
                step='1'
                onChange={e => setTempPowerPlanned(e.target.value)}
              />

              <LwsLabeledInput
                label='Defense Used'
                name='defense-mats-used'
                type='number'
                value={tempDefenseUsed}
                min='0'
                step='1'
                onChange={e => setTempDefenseUsed(e.target.value)}
              />
              <LwsLabeledInput
                label='Defense Plan'
                name='defense-mats-planned'
                type='number'
                value={tempDefensePlanned}
                min='0'
                step='1'
                onChange={e => setTempDefensePlanned(e.target.value)}
              />

              <LwsLabeledInput
                label='Evade Used'
                name='evade-mats-used'
                type='number'
                value={tempEvadeUsed}
                min='0'
                step='1'
                onChange={e => setTempEvadeUsed(e.target.value)}
              />
              <LwsLabeledInput
                label='Evade Plan'
                name='evade-mats-planned'
                type='number'
                value={tempEvadePlanned}
                min='0'
                step='1'
                onChange={e => setTempEvadePlanned(e.target.value)}
              />

              <LwsLabeledInput
                label='Mind Used'
                name='mind-mats-used'
                type='number'
                value={tempMindUsed}
                min='0'
                step='1'
                onChange={e => setTempMindUsed(e.target.value)}
              />
              <LwsLabeledInput
                label='Mind Plan'
                name='mind-mats-planned'
                type='number'
                value={tempMindPlanned}
                min='0'
                step='1'
                onChange={e => setTempMindPlanned(e.target.value)}
              />

              <LwsLabeledInput
                label='Luck Used'
                name='luck-mats-used'
                type='number'
                value={tempLuckUsed}
                min='0'
                step='1'
                onChange={e => setTempLuckUsed(e.target.value)}
              />
              <LwsLabeledInput
                label='Luck Plan'
                name='luck-mats-planned'
                type='number'
                value={tempLuckPlanned}
                min='0'
                step='1'
                onChange={e => setTempLuckPlanned(e.target.value)}
              />
            </div>
          </>
        )}
      </form>
    </LwsModal>
  );
}
